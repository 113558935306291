"use client";

import { useEffect } from "react";

let lastError: string | null = null;

export default function ErrorBoundary({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const logError = async () => {
    const errorKey = `${error.digest}-${error.message}`;
    if (lastError === errorKey) return;
    lastError = errorKey;

    await fetch("/api/log-error", {
      method: "POST",
      body: JSON.stringify({
        digest: error.digest,
        stack: error.stack,
        message: error.message,
      }),
    });
  };

  useEffect(() => {
    logError();
  }, [error]);

  return <div>Error occurred</div>;
}
